<template>
  <div>
    <!-- bannner图 -->
    <img src="@/assets/news/banner.png" alt="" class="banner" />
    <!-- 新闻区域 -->
    <div class="news" v-if="newsList.length">
      <img src="@/assets/news/news.png" alt="" class="new-img" />
      <div class="news-area">
        <div class="news-left" @click="goNewsDetail(newsList[showIndex].id)">
          <p class="news-time">{{ newsList[showIndex].createtime }}</p>
          <p class="news-title">{{ newsList[showIndex].title }}</p>
          <div class="line"></div>
          <div class="news-desc">{{ newsList[showIndex].ids }}</div>
          <div class="enter">
            <i class="iconfont icon-jiantou_xiangyou_o"></i>
            <span class="enter-text">Details</span>
          </div>
          <div class="change">
            <img
              src="@/assets/news/left.png"
              alt=""
              class="left-arrow"
              @click.stop="toggle(-1)"
            />
            <img
              src="@/assets/news/right.png"
              alt=""
              class="right-arrow"
              @click.stop="toggle(1)"
            />
          </div>
        </div>
        <img
          :src="baseURL + newsList[showIndex].image"
          alt=""
          class="news-png"
        />
      </div>
    </div>
    <!-- 荣誉区域 -->
    <div class="prize">
      <img src="@/assets/news/prize.png" alt="" class="prize-img" />
      <div class="prize-area">
        <div class="prize-left">
          <div class="prize-title">
            <p class="prize-text">荣誉奖项</p>
            <p class="prize-time">2003-{{ year }}</p>
          </div>
          <img src="@/assets/news/img2.png" alt="" class="prize-png" />
        </div>
        <div class="prize-right">
          <!-- <p class="prize-item">2003——荣誉奖项</p> -->
          <div class="prize-list">
            <div class="list-item" v-for="item in honorList" :key="item.id">
              <div>{{ item.title }}</div>
              <div v-html="item.content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsList, getHonorList } from "@/api";
export default {
  name: "News",
  data() {
    return {
      baseURL: "http://adminwxdesy.wxjoi.com/",
      showIndex: 0,
      newsList: [],
      honorList: [],
      year: "",
    };
  },
  created() {
    var date = new Date();
    this.year = date.getFullYear();
  },
  mounted() {
    this.getNews();
    this.getHonor();
  },
  methods: {
    // 获取新闻列表
    async getNews() {
      try {
        const res = await getNewsList();
        this.newsList = res.list;
      } catch (error) {
        alert(error);
      }
    },
    // 获取荣誉列表
    async getHonor() {
      try {
        const res = await getHonorList();
        this.honorList = res.list;
      } catch (error) {
        alert(error);
      }
    },
    // 点击翻页
    toggle(step) {
      const newIndex = this.showIndex + step;
      console.log(newIndex);
      if (newIndex >= this.newsList.length) {
        this.showIndex = this.newsList.length - 1;
      } else if (newIndex < 0) {
        this.showIndex;
      } else {
        this.showIndex = newIndex;
      }
    },
    // 去新闻详情
    goNewsDetail(id) {
      this.$router.push({
        path: "/detail",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  height: 320px;
  display: block;
}
.news {
  padding: 36px 43px 46px 43px;
  .new-img {
    width: 117px;
    height: 54px;
  }
  .news-area {
    margin-top: 39px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .news-left {
      width: 247px;
      .news-time {
        font-size: 7px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #000000;
        margin-bottom: 12px;
      }
      .news-title {
        font-size: 8px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #000000;
        width: 250px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 16px;
        cursor: pointer;
      }
      .line {
        content: "";
        width: 15px;
        height: 2px;
        background: #000000;
      }
      .news-desc {
        cursor: pointer;
        font-size: 6px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #5b5b5b;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        margin: 11px 0 18px 0;
      }
      .enter {
        cursor: pointer;
        .enter-text {
          font-size: 8px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #000000;
          margin-left: 8px;
        }
      }
      .change {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 17px;
        .left-arrow {
          width: 20px;
          height: 20px;
          margin-right: 7px;
        }
        .right-arrow {
          width: 20px;
          height: 20px;
        }
      }
    }
    .news-png {
      height: 137px;
      flex: 1;
      margin-left: 51px;
    }
  }
}
.prize {
  padding: 0px 43px 51px 43px;
  .prize-img {
    width: 116px;
    height: 55px;
  }
  .prize-area {
    margin-top: 34px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: 220px;
    .prize-left {
      padding-left: 43px;
      padding-right: 124px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .prize-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .prize-text {
          font-size: 7px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #000000;
          margin-bottom: 8px;
        }
        .prize-time {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #000000;
        }
      }
      .prize-png {
        width: 71px;
        height: 152px;
        margin-top: 36px;
      }
    }
    .prize-right {
      height: 220px;
      overflow-y: scroll;
      flex: 1;
      .prize-item {
        font-size: 8px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #000000;
      }
      .prize-list {
        margin-top: 7px;
        .list-item {
          font-size: 7px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #000000;
          padding-bottom: 10px;
          &:last-child {
            padding-bottom: 0px;
          }
        }
      }
    }
    .prize-right::-webkit-scrollbar {
      width: 1px;
      height: 220px;
    }
    .prize-right::-webkit-scrollbar-thumb {
      background: #000000;
    }
    .prize-right::-webkit-scrollbar-track {
      background: #c3c3c3;
    }
  }
}
</style>
